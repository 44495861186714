import React, {Fragment} from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

const CheckboxList = (props) => {
    let checkbox = ( 
        props.items.map(item=>{
          let checked = props.selected.find(x => x === item);
          return (
            <FormGroup check key={item}>
              <Label check className="active">
                <Input type="checkbox" 
                  value={item} defaultChecked={checked} 
                  onChange={props.onChange} />{' '} {item}
              </Label>
            </FormGroup>
          )
        })
      )
      return (
        <Fragment>
          <Label for="name">{props.title}</Label> {checkbox}
        </Fragment>
      )
}

export default CheckboxList;