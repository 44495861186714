import {decorate, action, observable} from 'mobx';

import location from './location'

class NavigationStore {

    location = location.default;

    setLocation(loc){
        this.location = loc;
    }
}

decorate(NavigationStore, {
    location: observable,
    setLocation: action
})

export default new NavigationStore();