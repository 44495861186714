import React from 'react';
import AvailabilityOptions from './../AvailabilityOptions';
import { FaCheck } from 'react-icons/fa';
import { FaTimes } from 'react-icons/fa';
import { FaQuestion } from 'react-icons/fa';

const availability = (props) => {

  const classes = `availability ${props.availabilityOption.toLowerCase()} ${props.selected ? "selected" : ""}`;
  let option = <FaQuestion color="#866420"/>;

  if (props.availabilityOption === AvailabilityOptions.Available) {
    option = <FaCheck color="#0f6f22"/>;
  }

  if (props.availabilityOption === AvailabilityOptions.Unavailable) {
    option = <FaTimes color="#6f0712"/>;
  }

  return (
    <div className={classes} onClick={() => props.click(props.availabilityOption)} >
      {option}
    </div>
  );
}

export default availability;