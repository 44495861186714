class CustomErrors {

  Messages = {};

  constructor() {
    this.Messages[6]= "Unable to login,  please check your password and try again.";
    
    this.Messages[7] =
    this.Messages[8] = 
    this.Messages[9] = "You've been logged out or need to login to view this, please login and try again.";

    this.Messages[10] =
    this.Messages[11] = 
    this.Messages[12] = 
    this.Messages[16] =
    this.Messages[17] = 
    this.Messages[18] = "Please try that again.";

    this.Messages[13] =
    this.Messages[14] = 
    this.Messages[15] = "Unable to delete user, please try that again.";

    this.Messages[19] =
    this.Messages[20] = 
    this.Messages[21] = "Unable to save, please try that again.";

    this.Messages[22] =
    this.Messages[23] = 
    this.Messages[24] = "Unable to set availability, please try that again.";

  }

}

export default new CustomErrors();