import React from 'react';

import navigationStore from './../../store/navigation'
import location from './../../store/navigation/location'

const ActionNav = (props) => {

    function handleClick(e){
        let navTo = e.target.name;
        navigationStore.setLocation(navTo);
    }

    return (
        <span className="hand nav-link hide-arrow">
            <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="/" id="navbarAction" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Select Action
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarAction">
                    <span className="hand dropdown-item" onClick={handleClick}>
                        <button className="dropdown-item" name={location.schedules}>Schedules</button>
                    </span>
                    <span className="hand dropdown-item" onClick={handleClick}>
                        <button className="dropdown-item" name={location.users}>Users</button>
                    </span>
                </div>
            </li>
        </span>
    )
}

export default ActionNav;