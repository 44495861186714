import {decorate, action, observable} from 'mobx';

import constants from './../../util/const'

class SessionStore {

    isLoggedIn = false;

    isAdmin = false;

    constructor() {
        this.configureLoginStatus();
    }

    configureLoginStatus() {
        this.isLoggedIn = this.hasValidCookie();
        this.isAdmin = this.hasAdminPermission();
    }

    hasValidCookie(){
        return this.getSessionKey().length > 0 && this.getUserProfile();
    }

    hasAdminPermission() {
        var userProfile = this.getUserProfile();
        if(userProfile && userProfile.permissions) {
             return userProfile.permissions.indexOf(constants.AdminPermission) > -1;
        }
        return false;
    }

    parseCookie(){
        let cookieArr = document.cookie.split(';');
        try{
            if(cookieArr && cookieArr.length > 0)
            {
                let tmuCookie = cookieArr.filter((x) => x.indexOf(constants.CookieSessionKey) !== -1);
                if(tmuCookie && tmuCookie.length === 1){
                    let tmuCookieValue = tmuCookie[0].split('=')[1];
                    return tmuCookieValue;
                }
            }
            return '';
        }
        catch (error) {
            return '';
        }
    }

    getSessionKey(){
        try{
            let tmuCookie = this.parseCookie();
            let tmuSessionId = tmuCookie.split('|')[0];
            return tmuSessionId;
        }
        catch (error) {
            return '';
        }
    };

    getUserProfile(){
        try{
            let tmuCookie = this.parseCookie();
            let tmuUserProfile = tmuCookie.split('|')[1];
            return JSON.parse(decodeURIComponent(tmuUserProfile));
        }
        catch (error) {
            return null;
        }
    };

    setSession(resp){
        const sessionId = resp.headers[constants.HeaderSessionKey];
        const user = resp.data.data;

        var exp = this.calculateExpirationDate(constants.SessionTimeout);
        var cookieValue = `${sessionId}|${encodeURIComponent(JSON.stringify(user))};path=/;expires=${exp.toUTCString()};`;
        document.cookie = `${constants.CookieSessionKey}=${cookieValue}`;

        this.configureLoginStatus();
    }

    killSession(){
        var epoch = new Date(0);
        document.cookie = `${constants.CookieSessionKey}=;path=/;expires=${epoch.toUTCString()};`;
        this.isLoggedIn = false;
        this.isAdmin = false;
    }

    calculateExpirationDate(mins){
        var now = new Date();
        var exp = new Date(now.getTime() + (mins * 60 * 1000));

        return exp;
    }
}

decorate(SessionStore, {
    isLoggedIn: observable,
    isAdmin: observable,
    getSessionKey: observable,
    getUserProfile: observable,
    setSession: action,
    killSession: action
})

export default new SessionStore();