import React, {useEffect, useRef} from 'react'
import config from '../../util/config'

const loadScript = (src) =>
  new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve()
    const script = document.createElement('script')
    script.src = src
    script.onload = () => resolve()
    script.onerror = (err) => reject(err)
    document.body.appendChild(script)
  })

const GoogleSignIn = props => {

  const {
    onSuccess,
    onFail
  } = props

  const client = useRef(null);

  useEffect(() => {
    const src = 'https://accounts.google.com/gsi/client'
    const id = config.clientId

    loadScript(src)
      .then(() => {
        client.current = window.google.accounts.oauth2.initCodeClient({
          client_id: id,
          scope: 'profile',
          ux_mode: 'popup',
          callback: (response) => {
            onSuccess(response)
          },
        });
      })
      .catch(console.error)

    return () => {
      const scriptTag = document.querySelector(`script[src="${src}"]`)
      if (scriptTag) document.body.removeChild(scriptTag)
    }
  }, [onSuccess, onFail])

  function getAuthCode() {
    // Request authorization code and obtain user consent
      if (client.current) {
        client.current.requestCode();
      } else{
        onFail(Error());
      }
  }

  return (
      <button type="button" className="btn btn-primary" onClick={() => getAuthCode()}>Login<span
          className="oi oi-account-login"></span>
      </button>
  )
}

export default GoogleSignIn
