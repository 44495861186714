class ErrorCodes{
  FailedGoogleLogin = 6;
  FailedLogin403 = 7;
  FailedLogin400 = 8;
  FailedLogin = 9;

  FailedSetAvailability403 = 22;
  FailedSetAvailability400 = 23;
  FailedSetAvailability = 24;

  FailedDeleteUser403 = 13;
  FailedDeleteUser400 = 14;
  FailedDeleteUser = 15;

  FailedUsers403 = 16;
  FailedUsers400 = 17;
  FailedUsers = 18;

  FailedSaveUser403 = 19;
  FailedSaveUser400 = 20;
  FailedSaveUser = 21;

  FailedGetSchedule403 = 10;
  FailedGetSchedule400 = 11;
  FailedGetSchedule = 12;

  GetLoginErrorCodeFromHttpCode(httpCode) {
    switch (httpCode) {
      case 403:
        return this.FailedLogin403;
      case 400:
        return this.FailedLogin400;
      default:
          return this.FailedLogin;
    }
  }

  GetSetAvailabilityErrorCodeFromHttpCode(httpCode) {
    switch (httpCode) {
      case 403:
        return this.FailedSetAvailability403;
      case 400:
        return this.FailedSetAvailability400;
      default:
          return this.FailedSetAvailability;
    }
  }

  GetDeleteUserErrorCodeFromHttpCode(httpCode) {
    switch (httpCode) {
      case 403:
        return this.FailedDeleteUser403;
      case 400:
        return this.FailedDeleteUser400;
      default:
          return this.FailedDeleteUser;
    }
  }

  GetUsersErrorCodeFromHttpCode(httpCode) {
    switch (httpCode) {
      case 403:
        return this.FailedUsers403;
      case 400:
        return this.FailedUsers400;
      default:
          return this.FailedUsers;
    }
  }

  GetSaveUserErrorCodeFromHttpCode(httpCode) {
    switch (httpCode) {
      case 403:
        return this.FailedSaveUser403;
      case 400:
        return this.FailedSaveUser400;
      default:
          return this.FailedSaveUser;
    }
  }

  GetScheduleErrorCodeFromHttpCode(httpCode) {
    switch (httpCode) {
      case 403:
        return this.FailedGetSchedule403;
      case 400:
        return this.FailedGetSchedule400;
      default:
          return this.FailedGetSchedule;
    }
  }
}

export default new ErrorCodes();