import React, {Fragment} from 'react';
import { Label, Input, FormFeedback } from 'reactstrap';

const LabelledTextbox = (props) => {
    
    return (
      <Fragment>
        <Label for={props.id}>{props.title}</Label>
        <Input disabled={props.disabled} 
          type={props.type} 
          name={props.id} 
          id={props.id} 
          defaultValue={props.value}
          onChange={props.onChange}
          maxLength={props.maxLength}
          invalid={props.invalid}
           />
          <FormFeedback>{props.title} is required.</FormFeedback>
      </Fragment>
    )
}

export default LabelledTextbox;