import React from 'react';
import IndividualSchedule from './../individual-schedule';
import ScheduleHeader from './ScheduleHeader';
import './GroupSchedule.css';

const groupSchedule = (props) => {

  const headerSchedule = getHeaderSchedule(props.groupSchedule);

  return (
    <div className="overflowContainer">
      <div className="container grpSchContainer">
        <ScheduleHeader scheduleDates={headerSchedule} />
        {
          props.groupSchedule.map(individualSchedule => {
            return <IndividualSchedule 
                    {...individualSchedule}
                    scheduleCount={headerSchedule.length}
                    key={individualSchedule.userInfo.userId} 
                  />
          })
        }
      </div>
    </div>
  );

  function getHeaderSchedule(groupSchedule) {
    const groupScheduleLengths = groupSchedule.map(x => { return x.schedule.length; });
    const largestScheduleIndex = groupScheduleLengths.indexOf(Math.max.apply(Math, groupScheduleLengths));
    return groupSchedule[largestScheduleIndex].schedule.map(
      s => { 
        return {
          scheduleDate: s.occurrence
        }
      }
    );
  }
}

export default groupSchedule;
