import React, { useState, useEffect, Fragment } from 'react';
import scheduleData from './../../../data/schedule';
import sessionStore from './../../../store/session';
import PersonalSchedule from './../../schedule/personal-schedule';
import UserScheduleHeader from './user-schedule-header';
import './UserSchedule.css';

const UserSchedule = () => {

  const [userScheduleData, setUserScheduleData] = useState([]);

  useEffect(() => {
    loadScheduleData();
  }, [])

  const loadScheduleData = async () => {
    if(sessionStore.isLoggedIn) {
      let userSchedule = await scheduleData.getUserScheduleData();
      setUserScheduleData(userSchedule);
    }
  }

  return (
    <Fragment>
      <UserScheduleHeader />
      <PersonalSchedule schedule={userScheduleData} />
    </Fragment>
  )
}

export default UserSchedule;