import React, { Fragment } from 'react';
import { FaRegClock } from 'react-icons/fa';

const UserInfo = (props) => {
  let hasSkills = props.userInfo.skills;

  return (
    <Fragment>
      <div className="row align-items-center">
        { hasSkills ?
          <div className="col-2 skillContainer">
            {
              props.userInfo.skills.map(skill => {
                const skillInitial = skill.substring(0, 1).toLowerCase();
                return <span className={"skill skill-" + skillInitial} key={props.userInfo.userId + skillInitial}>
                  {skillInitial.toUpperCase()}
                </span>
                })
            }
          </div>
          :
          null
        }
          <div className={`col-${hasSkills ? "10" : "12"} scheduleName`}>
            {props.userInfo.name}
          </div>
        </div>
        <div className="row">
          <div className="col-12 timezone">
            <FaRegClock /> {trimTimezone(props.userInfo.timezone)} {"(" + props.userInfo.offset + ")"}
          </div>
        </div>
    </Fragment>
  );

  function trimTimezone(timezone) {
    if(timezone) {
      let indexOfSlash = timezone.indexOf('/');
      return timezone.substring(indexOfSlash+1)
    }
    return "";
  }
}

export default UserInfo;