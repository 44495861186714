import {decorate, action, observable, computed} from 'mobx';

class NotificationStore {

    message = null;

    constructor() {
        this.message = null;
    }

    setMessage(message){
        this.message = message;
    }

    get hasMessage(){
        return this.message !== null && this.message !== 'undefined';
    }
}

decorate(NotificationStore, {
    message: observable,
    setMessage: action,
    hasMessage: computed
})

export default new NotificationStore();