import React, {useState, useEffect, Fragment } from 'react';
import ScheduleGridHeader from './schedule-grid-header';
import scheduleData from './../../../data/schedule';
import ScheduleRegion from './../../schedule/region';
import sessionStore from './../../../store/session'
import './ScheduleGrid.css';

const ScheduleGrid = () => {

  const [adminScheduleData, setAdminScheduleData] = useState([]);

  useEffect(() => {
    loadScheduleData();
  }, [])

  const loadScheduleData = async () => {
    if(sessionStore.isLoggedIn) {
      let adminScheduleData = await scheduleData.getAdminScheduleData();
      setAdminScheduleData(adminScheduleData);
    }
  }

  return (
      <Fragment>
        <ScheduleGridHeader />
        {
          adminScheduleData.map(region => {
          return region.groupSchedule.length > 0 ?
            <ScheduleRegion 
              region={region} 
              key={region.regionName} />
            :
              null;  
          })
        }
      </Fragment>
  );
}

export default ScheduleGrid;