import React, {Fragment} from 'react';
import { observer }  from 'mobx-react';
import PropTypes from 'prop-types';

import Login from '../login'
import ActionNav from './../action-nav'
import sessionStore from './../../store/session'

import './header.css'

const Header = (props) => {
    let showActionNav = sessionStore.isLoggedIn && sessionStore.isAdmin;
    let actionNav = showActionNav ? <ActionNav /> : <Fragment />
    return (
        <nav className="navigation navbar navbar-expand-lg navbar-light bg-light">
            <div className="container">
                <a className="navbar-brand" href="/">
                    <img className="logo" src={props.logoUrl} alt={props.title} />
                    <span className="brand">{props.title}</span>
                </a>
                <ul className="navbar-nav ml-auto">
                    {actionNav}
                    <li className="nav-item dropdown" id="profile">
                        <Login buttonText="Login" />
                    </li>
                </ul>
            </div>
        </nav>
    )
}

Header.propTypes = {
    title: PropTypes.string,
    logoUrl: PropTypes.string
};

export default observer(Header);