import moment from "moment";
import React from 'react';
import dateUtil from './../../../../../util/date';
import Availability from './../../Availability';
import AvailabilityOptions from './../../AvailabilityOptions';
import './SelectorContent.css';

const selectorContent = (props) => {

  const selectionChanged = (newAvailabilityOption) => {
    props.changedSelection(newAvailabilityOption);
  }

  const date = dateUtil.GetDateAsIs(props.selectionDate);
  var day = dateUtil.Day(date.getDay(), "ddd");
  var dateString = moment(date).format('DD/MM/YYYY');

  return (
    <div className="selectorContent">
      <div className="header">
        <strong>{day}</strong> {dateString} 
      </div>
      <div className="selectors">
        <Availability 
          availabilityOption={AvailabilityOptions.Available}
          click={selectionChanged}
          selected={props.currentSelection === AvailabilityOptions.Available}
        />
        <Availability 
          availabilityOption={AvailabilityOptions.NotSet} 
          click={selectionChanged} 
          selected={props.currentSelection === AvailabilityOptions.NotSet}
        />
        <Availability 
          availabilityOption={AvailabilityOptions.Unavailable} 
          click={selectionChanged} 
          selected={props.currentSelection === AvailabilityOptions.Unavailable}
        />
      </div>
      
    </div>
  );
}

export default selectorContent;