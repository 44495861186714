import constants from './../const'
import notificationStore from './../../store/notification'
import severity from './../../components/content/notification-dialog/Severity'
import customErrors from '../custom-errors'

class ErrorHandler {

    badRequestMessage(errorCode){
        return {
            severity: severity.Warning,
            title:  "Uh-oh!",
            heading: errorCode,
            message: customErrors.Messages[errorCode] || "",
            contactEmail: constants.ContactEmailAddress
        }
    }

    forbiddenMessage(errorCode){
        return {
            severity: severity.Warning,
            title:  "Restricted Access",
            heading: errorCode,
            message: customErrors.Messages[errorCode] || "Think you should have access?",
            contactEmail: constants.ContactEmailAddress
        }
    }

    defaultMessage(errorCode, message){
        return {
            severity: severity.Danger,
            title:  "Error",
            heading: errorCode,
            message: customErrors.Messages[errorCode] || message || "We are working to resolve this error!",
            contactEmail: constants.ContactEmailAddress
        }
    }

    async HandleHttpException(error){
        console.log(error);
        
        let message = {};
        if(error.response){
            let errorCode = error.Code;
            switch(error.response.status){
                case 400:
                    message = this.badRequestMessage(errorCode);
                    break;
                case 403:
                    message = this.forbiddenMessage(errorCode);
                    break;
                default:
                    message = this.defaultMessage(errorCode);
            }
        }else {
            message = this.defaultMessage(error.Code, error.message.concat("."));
        }
        
        notificationStore.setMessage(message);
    }

    async HandleOAuthException(error){
        console.log(error);
        
        let message =  this.forbiddenMessage(error.Code);
        
        notificationStore.setMessage(message);
    }
}

export default new ErrorHandler();