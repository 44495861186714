import React, { Fragment, useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import sessionStore from './../../../store/session';
import api from './../../../util/api';
import errorHandler from './../../../util/error-handler';
import ErrorCodes from '../../../util/custom-errors/ErrorCodes';
import DeleteUserModal from './../delete';
import UserModal from './../modal';

const UserList = (props) => {
    const [users, setUsers] = useState([]);
    const [permissionsList, setPermissionsList] = useState([]);
    const [skillsList, setSkillsList] = useState([]);

    useEffect(() => {
        loadUserData();
        loadRefData();
      }, [])

    const loadUserData = async () => {
        if(sessionStore.isLoggedIn) {
            try{
                let resp = await api.GetUserList();
                let respUsers = resp.data.data;
                respUsers.sort(function(a, b){return a.id - b.id});
                setUsers(respUsers);
            }
            catch(error){
                let errorCode = error.response ? error.response.status : null;
                error.Code = ErrorCodes.GetUsersErrorCodeFromHttpCode(errorCode);
                errorHandler.HandleHttpException(error);
            }
        }
    }

    const loadRefData = async () => {
        if(sessionStore.isLoggedIn) {
            try{
                let resp = await api.GetRefData();
                let respPermissions = resp.data.data.permissions;
                let respSkills = resp.data.data.skills;
                setPermissionsList(respPermissions);
                setSkillsList(respSkills);
            }
            catch(error){
                let errorCode = error.response ? error.response.status : null;
                error.Code = ErrorCodes.GetUsersErrorCodeFromHttpCode(errorCode);
                errorHandler.HandleHttpException(error);
            }
        }
    }

    function UserRows(props){
        return props.users.map((user, ix) => {
            let bookable = '';
            if(user.bookable === true)
            {
                bookable = 'Yes'
            }
            if(user.bookable === false)
            {
                bookable = 'No'
            }
            return (
                <tr key={ix}>
                    <th scope="row">{user.id}</th>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.timezone}</td>
                    <td>{bookable}</td>
                    <td>
                        <BulletList items={user.permissions} />
                    </td>
                    <td>
                        <BulletList items={user.skills} />
                    </td>
                    <td>
                        <UserModal 
                            user={user}
                            formTitle="Update User" 
                            permissionList={permissionsList}
                            skillList={skillsList}
                            onUserUpdated={loadUserData}/>
                    </td>
                    <td>
                        <DeleteUserModal 
                            user={user}
                            formTitle="Delete User" 
                            onUserUpdated={loadUserData}/>
                    </td>

                </tr>
            )
        })
    }

    function BulletList(props){
        if(props.items){
            let li = props.items.map(item => {
                return (
                    <li key={item}>
                        {item}
                    </li>
                    )
                })
            return <ul>{li}</ul>
        }
       
        return <div></div>;
    }

    return (
        <Fragment>
            <Table  responsive striped size="sm">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Timezone</th>
                        <th>Bookable</th>
                        <th>Permissions</th>
                        <th>Skills</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <UserRows users={users} />
                </tbody>
        </Table>
      </Fragment>
    )
}

export default UserList;