import React, { useState } from 'react';
import { FaCalendarAlt, FaInfo } from 'react-icons/fa';
import Popover, { ArrowContainer } from 'react-tiny-popover';


const ScheduleGridHeader = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <div className="row scheduleGridHeaderRow">
      <div className="col-10 scheduleGridHeader">
        <FaCalendarAlt />
        Schedules
      </div>
      <div className="col-2 align-self-end icon">
        <Popover
          isOpen={isPopoverOpen}
          position={'top'}
          disableReposition 
          padding={0}
          onClickOutside={() => setIsPopoverOpen(false)}
          content={({ position, targetRect, popoverRect }) => (
              <ArrowContainer
                  position={position}
                  targetRect={targetRect}
                  popoverRect={popoverRect}
                  arrowColor={'#ddd'}
                  arrowSize={10}
              >
                  <div className="selectorContent">
                    <div className="header">
                      <strong>Legend</strong>
                    </div>
                    <div className="selectors">
                      <span className="skill skill-100 skill-v">Visual Researcher / Designer</span>
                      <span className="skill skill-100 skill-w">Writer</span>
                      <span className="skill skill-100 skill-s">Storyboard Artist</span>
                    </div>
                  </div>
              </ArrowContainer>
            )}
        >
            <FaInfo onClick={() => setIsPopoverOpen(!isPopoverOpen)} />
        </Popover>
        
      </div>
    </div>
  )
}

export default ScheduleGridHeader;