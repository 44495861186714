import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { FaUserEdit } from 'react-icons/fa';
import { Button, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import api from './../../../util/api';
import errorHandler from './../../../util/error-handler';
import ErrorCodes from '../../../util/custom-errors/ErrorCodes';
import "./../user.css";
import CheckboxList from './checkbox-list';
import LabelledTextbox from './labelled-textbox';
import RadioButtonList from './radiobutton-list';


const UserModal = (props) => {
  const [modal, setModal] = useState(false);
  const [user, setUser] = useState(props.user);
  const [invalidFields, setinvalidFields] = useState([]);

  const bookableList = [
    {key: true, value: 'Yes'},
    {key: false, value: 'No'}
  ]

  const toggleModal = () => {
    setModal(!modal);
  }

  const handleNameChanged = (e) => {
    user.name = e.target.value;
    setUser(user);
  }

  const handleBookableChange = (e) => {
    user.bookable = (e.target.value === 'true');
    setUser(user);
  };

  const handlePermissionChange = (e) => {
    const item = e.target.value;
    const isChecked = e.target.checked;
    const permissions = handleCheckboxChange(item, isChecked, user.permissions);
    user.permissions = permissions;
    setUser(user);
  }

  const handleSkillChange = (e) => {
    const item = e.target.value;
    const isChecked = e.target.checked;
    const skills = handleCheckboxChange(item, isChecked, user.skills);
    user.skills = skills;
    setUser(user);
  }

  const handleCheckboxChange = (item, isChecked, list) => {
    let permissionIndex = list.indexOf(item);

    if(isChecked){
      if(permissionIndex === -1){
        list.push(item)
      }
    }
    else{
      if(permissionIndex > -1){
        list.splice(permissionIndex, 1);
      }
    }
    return list.sort();
  }

  const handleUpdateClick = async (e) => {
    if(validateFields()){
      await saveUser(user);
      props.onUserUpdated();
      toggleModal();
    }
  }

  const validateFields = () => {
    const messages = [...invalidFields];
    const fieldIndex = invalidFields.indexOf('Name');

    if(fieldIndex !== -1){
      messages.splice(fieldIndex, 1);
    }

    if(user.name.trim().length === 0){
      messages.push('Name');
    }
    setinvalidFields(messages);
    return messages.length === 0;
  }

  const isFieldInvalid = (field) => {
    return invalidFields.indexOf(field) > -1
  };

  const saveUser = async (userToSave) => {
    try{
      let minimalUser = {
        "id": userToSave.id,
        "name": userToSave.name,
        "bookable": userToSave.bookable,
        "permissions": userToSave.permissions,
        "skills": userToSave.skills
      };
      await api.SaveUser(minimalUser);
    }
    catch(error){
      let errorCode = error.response ? error.response.status : null;
      error.Code = ErrorCodes.GetSaveUserErrorCodeFromHttpCode(errorCode);
      errorHandler.HandleHttpException(error);
      toggleModal();
    }
  };

  let toggleButton = <div></div>;

  if(user && user.id)
  {
    toggleButton = <Button color="primary" onClick={() => toggleModal()} className="btnUserManagement">{props.buttonTitle}<FaUserEdit /></Button>
  }

  return (
    <Fragment>
      {toggleButton}
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{props.formTitle}</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <LabelledTextbox 
                  title="Name" 
                  type="text" 
                  id="name" 
                  value={user.name} 
                  onChange={handleNameChanged} 
                  maxLength="50" 
                  invalid={isFieldInvalid('Name')} 
                  required />
              </FormGroup>
              <FormGroup disabled>
                <LabelledTextbox 
                  title="Email" 
                  type="email" 
                  id="email" 
                  value={user.email} 
                  disabled 
                  maxLength="50" />
              </FormGroup>
              <FormGroup disabled>
                <LabelledTextbox 
                  title="Timezone" 
                  type="text" 
                  id="timezone" 
                  value={user.timezone} 
                  disabled 
                  maxLength="50" />
              </FormGroup>
              <FormGroup>
                <RadioButtonList 
                  title="Bookable" 
                  items={bookableList} 
                  selected={user.bookable} 
                  onChange={handleBookableChange} />
              </FormGroup>
              <FormGroup>
                <CheckboxList 
                  title="Permissions" 
                  items={props.permissionList} 
                  selected={user.permissions} 
                  onChange={handlePermissionChange} />
              </FormGroup>
              <FormGroup>
                <CheckboxList 
                  title="Skills" 
                  items={props.skillList} 
                  selected={user.skills} 
                  onChange={handleSkillChange} />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button  color="primary" onClick={handleUpdateClick}>Update</Button>{' '}
            <Button color="secondary" onClick={toggleModal}>Cancel</Button>
          </ModalFooter>
      </Modal>
    </Fragment>
  )
}

UserModal.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.number,
        email: PropTypes.string,
        name: PropTypes.string,
        timezone: PropTypes.string,
        permissions: PropTypes.arrayOf(PropTypes.string),
        skills: PropTypes.arrayOf(PropTypes.string),
        bookable: PropTypes.bool
    }),
    formTitle: PropTypes.string,
    buttonTitle: PropTypes.string,
    permissionList: PropTypes.arrayOf(PropTypes.string),
    skillList: PropTypes.arrayOf(PropTypes.string)
};

export default UserModal;