import React, { Fragment } from 'react';
import GroupSchedule from './../group-schedule';
import { FaGlobe } from 'react-icons/fa';
import './Region.css';

const scheduleRegion = (props) => {
  return (
    <Fragment>
      <div className="regionName">
        <FaGlobe /><h5>{props.region.regionName}</h5>
      </div>
      <GroupSchedule groupSchedule={props.region.groupSchedule} />
    </Fragment>
  );
}

export default scheduleRegion;