import moment from "moment";
import React from 'react';
import dateUtil from './../../../../util/date';

const scheduleHeader = (props) => {

  return (
    <div className="row headerRow align-items-center">
        <div className="col-2">
          <span className="schGridHeader">Astronaut</span>
        </div>
        <div className="col-10">
          <div className="row">
            {props.scheduleDates.map((s,i) => {

                const date = dateUtil.GetDateAsIs(s.scheduleDate);
                let day = dateUtil.Day(date.getDay(), "ddd");
                let isWeekend = dateUtil.IsWeekend(date.getDay());
                let dateString = moment(date).format('DD/MM/YYYY');

                return <div 
                          className={`col smallPad ${isWeekend ? "weekend" : ""}`} 
                          key={"schHeader"+i}>
                  <strong>{day}</strong> <span className="schDate">{dateString}</span>
                </div>
              })
            }
          </div>
        </div>
      </div>
    );
}

export default scheduleHeader;