import api from './../../util/api';
import errorHandler from './../../util/error-handler'
import ErrorCodes from '../../util/custom-errors/ErrorCodes';

const australasiaNzTimezonesPrefixes = ["asia", "pacific", "australia"];
const eurUkSaTimezonesPrefixes = ["africa", "atlantic", "europe"];
const americaCanadaTimezonesPrefixes = ["america"];

const australasiaNzRegion = "Australasia/NZ";
const eurUkSaRegion = "Eur/UK/SA";
const americaCanadaRegion = "US/Canada";
const otherRegion = "Other";

const getUserScheduleData = async () => {
    let schedule = [];
  
    try {
        let resp = await api.GetUserSchedule();
        if(isValidScheduleDataResponse(resp)) {
            schedule = resp.data.data
        }
      } catch(error) {
        let errorCode = error.response ? error.response.status : null;
        error.Code = ErrorCodes.GetScheduleErrorCodeFromHttpCode(errorCode);
        errorHandler.HandleHttpException(error);
    } finally {
        return schedule;
    }
  }

const getAdminScheduleData = async () => {
  let regions = [];

  try {
      let resp = await api.GetAdminSchedule();
      if(isValidScheduleDataResponse(resp)) {
          regions = initRegionSchedule();
          resp.data.data.map(userAndSchedule => 
            { 
                return processUserAndSchedule(userAndSchedule, regions)
            });
      }
    } catch(error) {
        let errorCode = error.response ? error.response.status : null;
        error.Code = ErrorCodes.GetScheduleErrorCodeFromHttpCode(errorCode);
        errorHandler.HandleHttpException(error);
  } finally {
      return regions;
  }
}

function isValidScheduleDataResponse(resp) {
    return resp.data && resp.data.success && resp.data.success === "true"
        && resp.data.data && resp.data.data.length > 0;
}

function initRegionSchedule() {
    return [
        {
            regionName: australasiaNzRegion,
            groupSchedule: []
        },
        {
            regionName: eurUkSaRegion,
            groupSchedule: []
        },
        {
            regionName: americaCanadaRegion,
            groupSchedule: []
        },
        {
            regionName: otherRegion,
            groupSchedule: []
        },
    ];
}

function processUserAndSchedule(userAndSchedule, regions) {
     var userSchedule = getUserSchedule(userAndSchedule);
     addUserScheduleToRegion(userSchedule, regions);
}

function getUserSchedule(userAndSchedule) {
    return {
        userInfo: {
            userId: userAndSchedule.userId,
            name: userAndSchedule.name,
            timezone: userAndSchedule.timezone,
            offset: userAndSchedule.offset,
            skills: userAndSchedule.skills
        },
        schedule: getSchedule(userAndSchedule)
    };
}

function getSchedule(userAndSchedule) {
    const schedule = [];
    for(let i = 0; i < userAndSchedule.ids.length; i++) {
        schedule.push({
            "id": userAndSchedule.ids[i],
            "userId": userAndSchedule.userId,
            "status": userAndSchedule.statuses[i],
            "occurrence": userAndSchedule.occurrences[i]
        });
    }
    return schedule;
}

function addUserScheduleToRegion(processedUserAndSchedule, regions){

    const timezonePrefixesSet = [
        [americaCanadaTimezonesPrefixes, americaCanadaRegion], 
        [australasiaNzTimezonesPrefixes, australasiaNzRegion], 
        [eurUkSaTimezonesPrefixes, eurUkSaRegion]
    ];

    let added = false;

    for(let i = 0; i < timezonePrefixesSet.length; i++) {
        if(addUserScheduleToRegionByTimezone(
            processedUserAndSchedule, 
            regions, 
            timezonePrefixesSet[i][0], 
            timezonePrefixesSet[i][1])) 
        {
            added = true;
        }
    }

    if(!added) {
       const other = regions.find(x => x.regionName === otherRegion);
       other.groupSchedule.push(processedUserAndSchedule);
    }
}

function addUserScheduleToRegionByTimezone(processedUserAndSchedule, regions, timezonesPrefix, regionName) {
    if(processedUserAndSchedule.userInfo.timezone){
        const userTimezone = processedUserAndSchedule.userInfo.timezone.toLowerCase();
        if (timezonesPrefix.find(x => userTimezone.indexOf(x) >= 0)) {
            const regionMatch = regions.find(x => x.regionName === regionName);
            if (regionMatch) {
                regionMatch.groupSchedule.push(processedUserAndSchedule);
                return true;
            }
        }    
    }
    return false;
}

export default {
  getAdminScheduleData: getAdminScheduleData,
  getUserScheduleData: getUserScheduleData
}