import React, {Fragment} from 'react';
import { observer }  from 'mobx-react';
import sessionStore from './../../store/session'
import NotificationDialog from './notification-dialog'
import notificationStore from './../../store/notification'
import ScheduleGrid from './schedule-grid';
import UserSchedule from './user-schedule';
import UserList from './../user/list'
import navigationStore from './../../store/navigation'
import location from './../../store/navigation/location'
import "./Content.css";

const Content = (props) => {
  const getNotifications = () => {
    let hasMessage = notificationStore.hasMessage;
    let notifications;

    if(hasMessage){
      notifications = <NotificationDialog 
                        severity={notificationStore.message.severity}
                        title={notificationStore.message.title}
                        heading={notificationStore.message.heading}
                        message={notificationStore.message.message}
                        contactEmail={notificationStore.message.contactEmail} />
    }
    return notifications;
  }

  const getDefaultContent = () => {
    return (
          <Fragment>
            <h4 className="card-title">
              <span className="oi oi-account-login"></span>
              Login
            </h4>
            <p className="card-text">
                Please login using your <u>themoonunit.net</u> account from the top right to continue
            </p>
            <p className="card-text">
              You can also download our mobile app below:<br/>
            </p>
            <hr/>
            <div className="text-center link-alignment">
              <a href="https://play.google.com/store/apps/details?id=com.themoonunit.admin">
                <img
                  alt="Get it on Google Play"
                  src="/assets/imgs/playstore.png"
                  className="img-link img-android"
                />
              </a>

              <a href="itms://apps.apple.com/app/the-moon-unit/id1325986189">
                <img
                  alt="Download on the App Store"
                  src="/assets/imgs/appstore.svg"
                  className="img-link img-ios"
                />
              </a>
            </div>
          </Fragment>
    )
  }

  const getScheduleContent = () => {
    return (
        <ScheduleGrid />
    )
  }

  const getUsersContent = () => {
    return (
        <UserList />
    )
  }

  let content =  getDefaultContent();

  if(sessionStore.isLoggedIn){
    if(sessionStore.isAdmin) {
      let goTo = navigationStore.location;
      switch(goTo)
      {
        case location.schedules:
            content = getScheduleContent();
            break;
        case location.users:
          content = getUsersContent();
          break;
        default:
            content = getScheduleContent();
      }
    } else {
      content = <UserSchedule />
    }
    
  }

  return (
    <div className="container">
      <div id="schedule" className="card">
        { getNotifications() }
        <div className="card-body">
          { content }
        </div>
      </div>
    </div>
  )
}

export default observer(Content);