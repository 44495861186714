import moment from "moment";
import React from 'react';
import dateUtil from './../../../util/date';
import AvailabilitySelector from './../availability-selector';
import './PersonalSchedule.css';

const personalSchedule = (props) => {

  return (
    <div className="container userSchedule" >
      {
        props.schedule.map(s => {

          const date = dateUtil.GetDateAsIs(s.occurrence);
          let day = dateUtil.Day(date.getDay(), "ddd");
          let dateString = moment(date).format('DD/MM/YYYY');

          return <div className="row align-items-center" key={s.id}>
              <div className="col-6">
                <strong>{day}</strong> ({dateString})
              </div>
              <div className="col-6">
                <AvailabilitySelector 
                  scheduleId={s.id} 
                  userId={s.userId} 
                  availability={s.status}
                  selectionDate={s.occurrence}
                />
              </div>
            </div>
        })
      }
    </div>
  );
}

export default personalSchedule;