import React from 'react';
import AvailabilitySelector from './../availability-selector';
import UserInfo from './UserInfo';
import './IndividualSchedule.css';

const individualSchedule = (props) => {

  return (
    <div className="row align-items-center individualRow">
      <div className="col-2 smallPadRight">
        <UserInfo userInfo={props.userInfo} />
      </div>
      <div className="col-10">
        <div className="row">
          {props.schedule.map(item => {
            return <div className="col smallPad" key={item.id}>
                <AvailabilitySelector scheduleId={item.id}
                                      userId={item.userId}
                                      availability={item.status}
                                      selectionDate={item.occurrence}
                />
              </div> 
          })}
          {
            padColumns(props.schedule.length, props.scheduleCount, props.userInfo.userId)
          }
        </div>
      </div>
    </div>
  );

  function padColumns(currentCount, requiredCount, identifier) {
    if (currentCount && currentCount < requiredCount) {
      var i;
      var itemsToPad = requiredCount - currentCount;
      var padding = [];
      for (i = 0; i < itemsToPad; i++) {
        var keyId = identifier + "-" + i;
        padding.push(<div className="col smallPad" key={"colPad" + keyId}></div>);
      }
      return padding;
    }
  }
}

export default individualSchedule;