class Const {
    CookieSessionKey = 'tmu-session';
    
    HeaderSessionKey = 'x-tmu-session';

    AdminPermission = 'Admin';

    SessionTimeout = 60;

    ContactEmailAddress = 'emmett@themoonunit.net';
}

export default new Const();