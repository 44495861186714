import React from 'react';
import Popover, { ArrowContainer } from 'react-tiny-popover';

import SelectorContent from './Content';

const selector = (props) => {
    
    const propagateSelection = (newSelection) => {
        props.onSelected(newSelection);
    }

    return (
    <Popover
        isOpen={props.isPopoverOpen}
        position={'top'}
        disableReposition 
        padding={0}
        onClickOutside={props.togglePopOver}
        content={({ position, targetRect, popoverRect }) => (
            <ArrowContainer
                position={position}
                targetRect={targetRect}
                popoverRect={popoverRect}
                arrowColor={'#ddd'}
                arrowSize={10}
            >
                <SelectorContent 
                    currentSelection={props.currentSelection}
                    selectionDate={props.selectionDate}
                    changedSelection={propagateSelection}
                />
            </ArrowContainer>
        )}
    >
        {props.children}
    </Popover>
    );
}

export default selector;