import React, { useState } from 'react';
import { FaCalendarAlt, FaInfo } from 'react-icons/fa';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import sessionStore from './../../../../store/session';
import { FaCheck } from 'react-icons/fa';
import { FaTimes } from 'react-icons/fa';
import { FaQuestion } from 'react-icons/fa';

const UserScheduleHeader = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <div className="row scheduleGridHeaderRow">
      <div className="col-10 scheduleGridHeader">
        <FaCalendarAlt />
        Set your schedule, {sessionStore.getUserProfile().name}!
      </div>
      <div className="col-2 align-self-end icon">
        <Popover
          isOpen={isPopoverOpen}
          position={'top'}
          disableReposition 
          padding={0}
          onClickOutside={() => setIsPopoverOpen(false)}
          content={({ position, targetRect, popoverRect }) => (
              <ArrowContainer
                  position={position}
                  targetRect={targetRect}
                  popoverRect={popoverRect}
                  arrowColor={'#ddd'}
                  arrowSize={10}
              >
                  <div className="selectorContent">
                    <div className="header">
                      <strong>Legend</strong>
                    </div>
                    <div className="container userScheduleLegend">
                      <div className="row">
                        <div className="col-4">
                          <div className="availability available">
                           <FaCheck color="#0f6f22"/>
                          </div>
                        </div>
                        <div className="col-8">Available</div>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <div className="availability notset">
                            <FaQuestion color="#866420"/>
                          </div>
                        </div>
                        <div className="col-8">Not Set</div>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <div className="availability unavailable">
                            <FaTimes color="#6f0712"/>
                          </div>
                        </div>
                        <div className="col-8">Unavailable</div>
                      </div>
                    </div>
                  </div>
              </ArrowContainer>
            )}
        >
            <FaInfo onClick={() => setIsPopoverOpen(!isPopoverOpen)} />
        </Popover>
        
      </div>
    </div>
  )
}

export default UserScheduleHeader;