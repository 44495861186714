import React, { Fragment } from 'react';
import {observer} from 'mobx-react'

import sessionStore from './../../store/session'
import apiUtil from './../../util/api'
import errorHandler from './../../util/error-handler'
import ErrorCodes from '../../util/custom-errors/ErrorCodes';

import './login.css'
import GoogleSignIn from '../GoogleSignIn';

class Login extends React.Component {   
    constructor() {
        super();
        this.state = {
            isLoading: false
        };

        this.successCallback = this.successCallback.bind(this);
        this.failedCallback = this.failedCallback.bind(this);
        this.logout = this.logout.bind(this);
    }

    async successCallback(response){
        if(response.code) {
            let authCode = response.code;

            try{
                let resp = await apiUtil.UserLogin(authCode);
                sessionStore.setSession(resp);
            }
            catch(error){
                sessionStore.killSession();
                let errorCode = error.response ? error.response.status : null;
                error.Code = ErrorCodes.GetLoginErrorCodeFromHttpCode(errorCode);
                errorHandler.HandleHttpException(error);
            }
            finally{
                this.toggleLoading(false);
            }
        }
    }

    failedCallback(resp){
        this.logout();
        resp.Code = ErrorCodes.FailedGoogleLogin;
        errorHandler.HandleOAuthException(resp);
    }

    async logout(){
        try{
            await apiUtil.UserLogout();
        }
        catch(error){
            let errorCode = error.response ? error.response.status : null;
            error.Code = ErrorCodes.GetLoginErrorCodeFromHttpCode(errorCode);
            errorHandler.HandleHttpException(error);
        }
        finally{
            sessionStore.killSession();
            this.toggleLoading(false);
        }
    }

    toggleLoading(loading){
        this.setState({ isLoading: loading });
    }

    renderLogin(renderProps){
        return (
            <Fragment>
                <button type="button" 
                    className="btn btn-primary" 
                    id="googleSignIn" 
                    onClick={(event) => { this.toggleLoading(true); 
                        renderProps.onClick();}} 
                    disabled={renderProps.disabled}>
                        {this.props.buttonText}
                        <span className="oi oi-account-login" />
                </button>
            </Fragment>
        )
    }
    
    renderProfile(renderProps){
        return  (
            <Fragment>
                <span className="hand nav-link dropdown-toggle hide-arrow" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <div className="profile-container">
                        <div className="profile-picture">
                            <img src={sessionStore.getUserProfile().picture} alt="Profile" />
                        </div>
                        <div className="profile-info">
                            <h6>{sessionStore.getUserProfile().name}<small>{sessionStore.getUserProfile().email}</small></h6>
                        </div>
                    </div>
                </span>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <span className="hand dropdown-item" onClick={this.logout}>
                        <span className="oi oi-account-logout"></span> Logout
                    </span>
                </div>
            </Fragment>
        )
    }

    renderLoading(){
        return (
            <div className="loading btn btn-light" role="button">
                <span className="oi oi-reload rotate"></span>
                Loading...
            </div>
        )
    }

    render() {
        const loggedInState = sessionStore.isLoggedIn;
        let button = this.renderLoading();

        if(!this.state.isLoading){
            if(!loggedInState){
                button = <GoogleSignIn onSuccess={this.successCallback} onFail={this.failedCallback}/>
            }
            else {
                button = this.renderProfile()
            }
        }

        return (
            <Fragment>
                { button }
            </Fragment>
        )
    }
}

export default observer(Login);