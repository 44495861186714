import React, {Fragment} from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

const RadioButtonList = (props) => {
    let radioButton = (
        props.items.map(item=>{
          let checked = item.key === props.selected;
          return (
            <FormGroup check key={item.key}>
              <Label check>
                <Input type="radio"
                  className="active"
                  name="radRadioButtons"
                  value={item.key}
                  defaultChecked={checked}
                  onChange={props.onChange} /> {' '} {item.value}
                </Label>
              </FormGroup>
            )
        })
      )
      return (
        <Fragment>
          <Label for="name">{props.title}</Label> {radioButton}
        </Fragment>
      );
}

export default RadioButtonList;