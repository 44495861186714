import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import { FaTimes } from 'react-icons/fa';
import { Button, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import sessionStore from './../../../store/session';
import api from "./../../../util/api";
import errorHandler from "./../../../util/error-handler";
import ErrorCodes from "../../../util/custom-errors/ErrorCodes";
import LabelledTextbox from "./../modal/labelled-textbox";
import "./../user.css";

const DeleteUserModal = props => {
  const [modal, setModal] = useState(false);
  const [user] = useState(props.user);

  const toggleModal = () => {
    setModal(!modal);
  };

  const handleDeleteClick = async e => {
    await deleteUser(user);
    props.onUserUpdated();
    toggleModal();
  };

  const deleteUser = async userToDelete => {
    try {
      let minimalUser = {
        id: userToDelete.id
      };
      await api.DeleteUser(minimalUser);
    } catch (error) {
      let errorCode = error.response ? error.response.status : null;
      error.Code = ErrorCodes.GetDeleteUserErrorCodeFromHttpCode(errorCode);
      errorHandler.HandleHttpException(error);
      toggleModal();
    }
  };

  let toggleButton = <div></div>;

  if (user && user.id) {
    const disable = sessionStore.getUserProfile().id === user.id;
    toggleButton = (
      <Button color="danger" onClick={() => toggleModal()} disabled={disable} className="btnUserManagement">
        {props.buttonTitle}
        <FaTimes/>
      </Button>
    );
  }

  return (
    <Fragment>
      {toggleButton}
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{props.formTitle}</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup disabled>
              <LabelledTextbox
                title="Name"
                type="text"
                id="name"
                value={user.name}
                maxLength="50"
                disabled
              />
            </FormGroup>
            <FormGroup disabled>
              <LabelledTextbox
                title="Email"
                type="email"
                id="email"
                value={user.email}
                disabled
                maxLength="50"
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDeleteClick}>
            Delete
          </Button>{" "}
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

DeleteUserModal.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number
  })
};

export default DeleteUserModal;
