import React, {Fragment} from 'react'
import PropTypes from 'prop-types';

const NotificationDialog = (props) => {

    const getContactEmail = (contactEmail) => {
        if(contactEmail && contactEmail.length > 0){
            return <Fragment><a href={"mailto:" + contactEmail}>{contactEmail}</a></Fragment>
        }
    }

    let classes = `error alert fade show alert-${props.severity}`
    let contactEmail = getContactEmail(props.contactEmail); 

    return (
        <div className={classes} role="alert">
            <button type="button" className="close alert-dismissible" data-dismiss="alert">
                <span aria-hidden="true">×</span>
            </button>
            <h4 className="alert-heading">
                <span className="oi oi-pulse"></span>
                {props.title}
            </h4>
            <hr />
            <p>
                {props.message} If this problem continues please contact {contactEmail}
            </p>
            {props.heading &&
            <p className="mb-0">
                Error code: #{props.heading}
            </p>
            }
        </div>
    )
}

NotificationDialog.propTypes = {
    severity:  PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    heading: PropTypes.string,
    message: PropTypes.string,
    contactEmail: PropTypes.string
};
  
export default NotificationDialog;