import React, { Fragment } from 'react';
import './App.css';
import Header from './components/header'
import Content from './components/content'

const App = (props) => {

  const getLogoUrl = () => {
    return `${process.env.PUBLIC_URL}/assets/imgs/logo.jpg`
  }

  return (
    <Fragment>
      <Header 
        title="Mission Control" 
        logoUrl={getLogoUrl()} />
      <Content />
    </Fragment>
  );
}

export default App;
