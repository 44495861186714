const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

const day = (day, format) => {
  let dayName = dayNames[day];
  if(format) {
    return dayName.substring(0, format.length);
  }
  return dayName;
}

const isWeekend = (day) => {
  if(day === 0 || day === 6) {
    return true;
  }
  return false;
}

const getDateForDisplay = (date) => {
  return date.toLocaleDateString("en-GB");
}

const getDateAsIs = (date) => {
  var dayOfDate =  date.substring(
    date.lastIndexOf("-") + 1,
    date.lastIndexOf("T")
  );

  let originalDate = new Date(date);

  if (originalDate.getDate() - dayOfDate !== 0) {
    return new Date(originalDate.getTime() + (24 * 3600 * 1000));
  }

  return originalDate;
}

export default {
  Day: day,
  IsWeekend: isWeekend,
  GetDateForDisplay: getDateForDisplay,
  GetDateAsIs: getDateAsIs
}