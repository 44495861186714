import React, {useState} from 'react';
import errorHandler from './../../../util/error-handler'
import ErrorCodes from '../../../util/custom-errors/ErrorCodes';
import PropTypes from 'prop-types';
import Availability from './Availability';
import Selector from './Selector';
import Api from './../../../util/api';
import './availability.css'

const AvailabilitySelector = (props) => {
  const [popOverOpen, setPopOverOpen] = useState(false);
  const [currentAvailabilty, setCurrentAvailability] = useState(props.availability);

  const togglePopover = () => {
      setPopOverOpen(!popOverOpen);
  }

  const onSelected = async (newSelection) => {
    setPopOverOpen(!popOverOpen);

    try {
      await Api.SetAvailability({
                  scheduleId: props.scheduleId,
                  newStatus: newSelection,
                  userId: props.userId
                });
      setCurrentAvailability(newSelection);
    } catch (e) {
      let errorCode = e.response ? e.response.status : null;
      e.Code = ErrorCodes.GetSetAvailabilityErrorCodeFromHttpCode(errorCode);
      errorHandler.HandleHttpException(e);
    }
  }

  return (
    <Selector 
      isPopoverOpen={popOverOpen}
      togglePopOver={togglePopover}
      onSelected={onSelected}
      currentSelection={currentAvailabilty}
      selectionDate={props.selectionDate}
    >
      <Availability click={togglePopover} availabilityOption={currentAvailabilty} />
    </Selector>
  );
}

AvailabilitySelector.propTypes = {
  scheduleId: PropTypes.number,
  userId: PropTypes.number,
  selectionDate: PropTypes.string,
  availability: PropTypes.string,
};

export default AvailabilitySelector;