import axios from 'axios'
import sessionStore from './../../store/session'
import config from './../config'
import constants from './../const'


class Api {
    async UserLogin(authCode){
        let resp = await axios.post(`${config.apiUrl}/auth/login`, 
            {
                authCode: authCode,
                admin: true,
                timezone: "UTC"
            }
        )
        return resp;
    }

    async UserLogout(){
        let resp = await axios.get(`${config.apiUrl}/auth/logout`, this.getHeader());
        return resp;
    }

    async SetAvailability(availability) {
        if(sessionStore.isAdmin) {
            return await this.SetAdminAvailability(availability);
        }
        return await this.SetUserAvailability(availability);
    }

    async SetAdminAvailability(availability) {
        let resp = await axios.post(`${config.apiUrl}/schedule/admin/set`, {
            schId: availability.scheduleId,
            status: availability.newStatus,
            userid: availability.userId
          }, this.getHeader()
        );
        return resp;
    }

    async SetUserAvailability(availability) {
        let resp = await axios.post(`${config.apiUrl}/schedule/set`, {
            schId: availability.scheduleId,
            status: availability.newStatus,
          }, this.getHeader()
        );
        return resp;
    }

    async GetAdminSchedule() {
        let resp = await axios.post(`${config.apiUrl}/schedule/admin`, {}, this.getHeader());
        return resp;
    }

    async GetUserSchedule() {
        let resp = await axios.get(`${config.apiUrl}/schedule/`, this.getHeader());
        return resp;
    }

    async SaveUser(user) {
        let resp = await axios.put(`${config.apiUrl}/admin/user/set`, user, this.getHeader());
        return resp;
    }

    async GetUserList() {
        let resp = await axios.get(`${config.apiUrl}/admin/user/list`, this.getHeader());
        return resp;
    }

    async GetRefData() {
        let resp = await axios.get(`${config.apiUrl}/admin/ref/all`, this.getHeader());
        return resp;
    }

    async DeleteUser(id) {
        let resp = await axios.put(`${config.apiUrl}/admin/user/delete`, id, this.getHeader());
        return resp;
    }

    getHeader() {
        let json = `{ "headers": { "${constants.HeaderSessionKey}": "${sessionStore.getSessionKey()}" } }`;
        return JSON.parse(json);
    }
}

export default new Api();